<template>
  <b-modal ref="modal2" id="modal2" title="Treatment Reports" centered hide-footer @click.self="hide()">

    <div class="row mt-4">
        <div class="form-group row" v-for="(input,k) in prescriptionMedicine" :key="k">
            <div class="col-12 col-lg-5 mb-3 mb-lg-0">
                <multiselect v-model="input.report" :options="reports" :multiple="false"
                    :close-on-select="true" label="report_name"
                    :clear-on-select="false" :preserve-search="true" placeholder="Select Report Type"
                    :preselect-first="false" :showLabels="false" @input=updateIPDDetails()
                    ></multiselect>
            </div>
                <date-range-picker
                    class="col-12 col-lg-5 mb-3 mb-lg-0"
                    ref="picker"
                    :min-date="MINDATE"
                    :max-date="MAXDATE"
                    opens="bottom"
                    :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy HH:mm' }"
                    :single-date-picker="true"
                    :showDropdowns="showDropdowns"
                    :show-week-numbers= "false"
                    :time-picker="true"
                    :autoApply="true"
                    v-model="input.date"
                    :linked-calendars="true"
                    :dateFormat="dd-mmm-yyyy"
                    placeholder="Report Date"
                />
            <span class="col-sm-2">
                <svg xmlns="http://www.w3.org/2000/svg" @click="addField()" v-show="k == prescriptionMedicine.length-1" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" /></svg>
                <svg xmlns="http://www.w3.org/2000/svg"  @click="removeField(k)" v-show="k || ( !k && prescriptionMedicine.length > 1)" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="#EC4899" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /></svg>
            </span>
        </div>
    </div>

    <div class="row mt-4 mb-3">
        <div class="col-md-1"></div>
        <div class="col-md-11">
            <b-button v-if="is_btn_spinner" variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
            <div v-else class="row">
                <button type="button"
                          class="col-md-4 btn btn-outline-secondary me-2 mb-3 mb-lg-0" @click="hide()">Close</button>
                <button v-if="treatment_reports" type="button" class="col-md-6 btn btn-gradient-primary me-2" @click="addUpdateReports()">Update Reports</button>
                <button v-else type="button" class="col-md-6 btn btn-gradient-primary me-2" @click="addUpdateReports()">Add Reports</button>
            </div>
          </div>
      </div>
  </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";

export default {
    components: {
      Multiselect,
      DateRangePicker,
    },
    data() {
        const now = new Date()
        const maxDate = new Date(new Date(now.getFullYear(), now.getMonth() + 3, now.getDate()))
        const minDate = new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate()))
        return {
          moment,
          ipd:null,
          treatment_reports:null,
          prescriptionMedicine: [],
          MAXDATE:maxDate,
          MINDATE:minDate,
          NOW:now,
          reports:[],
          is_btn_spinner:false,
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions("hospital",["getPrescriptionReportListData", "addIPDReportData", "editIPDReportData"]),
        show() {
            this.$refs.modal2.show();
            this.getReportList();
        },

        hide() {
            this.$emit('hide', true)
            this.$refs.modal2.hide();
        },

        clearData() {
            this.prescriptionMedicine = []
            this.treatment_reports = null
        },

        receiveData(ipddetails, treatmentReports) {
            this.ipd = ipddetails
            this.treatment_reports = treatmentReports
            console.log(this.treatment_reports)
            this.setIPDTreatmentDetails()
        },

        setIPDTreatmentDetails() {
            if(this.treatment_reports && this.treatment_reports.length > 0) {
                this.treatment_reports.forEach((obj,i)=>{
                    this.prescriptionMedicine.push({
                        id: obj.prescription_report_id,
                        report: obj,
                        date: {startDate: obj.report_date, endDate:obj.report_date},
                    });
                });
            } else {
                this.addField()
            }
        },

        addField() {
            this.prescriptionMedicine.push({
                id: "",
                report: [],
                date: ''
            });
        },

        removeField(index) {
            this.prescriptionMedicine.splice(index, 1);
        },

        clearFieldData(index) {
            this.prescriptionMedicine[index].medicine_id = '';
            this.prescriptionMedicine[index].medicine_name = '';
            this.prescriptionMedicine[index].date = '';
        },

        getReportList(){
          this.getPrescriptionReportListData({
            filter_value:'',
          }).then((response) => {
            if (response.response_code == 200) {
              this.reports = response.data;
            }
          });
        },

        addUpdateReports() {
            let reports_list = [];

            if (this.prescriptionMedicine.length == 0) {
                this.$toasted.error("Please select report", {duration: 2000,});
                return;
            }

            this.prescriptionMedicine.forEach((el,i)=>{
                let report_id = el.report.report_id;
                let report_name = el.report.report_name;
                let report_date = el.date;

                if (!report_id || report_id == '' || !report_name || report_name == '') {
                    this.$toasted.error("Please select report", {duration: 2000,});
                    return;
                }
                if (!report_date || !report_date.startDate && !report_date.endDate) {
                    this.$toasted.error("Please select report date", {duration: 2000,});
                    return;
                }

                let report_api = {
                    id: el.id,
                    report_id: report_id,
                    report_date: moment(String(report_date.startDate)).format('YYYY-MM-DD HH:mm:ss'),
                }
                reports_list.push(report_api);
            });

            this.is_btn_spinner = true;
            var bodyFormData = new FormData();
            bodyFormData.append('reports', JSON.stringify(reports_list));
            bodyFormData.append('ipd_id', this.ipd.id);

            if(this.treatment_reports.length > 0) {
                this.editIPDReportData(bodyFormData).then((response) => {
                    this.is_btn_spinner = false;
                    if (response.response_code == 200) {
                        this.$toasted.success(response.message, {duration: 2000,});
                        this.hide()
                    } else{
                        this.$toasted.error(response.message, {duration: 2000,});
                    }
                });
            } else {
                this.addIPDReportData(bodyFormData).then((response) => {
                    this.is_btn_spinner = false;
                    if (response.response_code == 200) {
                        this.$toasted.success(response.message, {duration: 2000,});
                        this.hide()
                    } else{
                        this.$toasted.error(response.message, {duration: 2000,});
                    }
                });
            }
        },
    }
}
</script>
<style scoped>
.cls-sugg {width: auto;height: 172px;overflow: scroll;}
.prod-itm-select {
  padding: 9px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
