<template>
  <b-modal ref="modal2" id="modal2" title="Treatment Details" centered size="huge-treatment" hide-footer @hide="hide()">

    <div class="row">
        <div class="col-sm-4">
            <input type="text" class="form-control" placeholder="Treatment Name" v-model="$v.typeform.treatment_name.$model" :class="{ 'is-invalid':  $v.typeform.treatment_name.$error  }" name="treatment_name"/>
            <div v-if="$v.typeform.treatment_name.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.treatment_name.required">Please enter treatment name</span>
            </div>
        </div>
        <div class="col-sm-6"></div>
        <div class="col-sm-2">
            <multiselect v-model="$v.typeform.selected_ipd_treatment_status.$model" :options="ipd_treatment_status" :multiple="false"
            :close-on-select="true" label="name"
            :clear-on-select="false" :preserve-search="true" placeholder="Select Status"
            :preselect-first="false" :showLabels="false" @input=updateIPDDetails()
            ></multiselect>
        </div>
    </div>
    <div class="row mt-4">
        <div class="form-group row" v-for="(input,k) in prescriptionMedicine" :key="k">
            <date-range-picker
                class="col-12 col-lg-2 mb-3 mb-lg-0"
                ref="picker"
                :min-date="MINDATE"
                :max-date="MAXDATE"
                opens="bottom"
                :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy HH:mm' }"
                :single-date-picker="false"
                :showDropdowns="showDropdowns"
                :show-week-numbers= "false"
                :time-picker="true"
                :autoApply="true"
                v-model="input.duration"
                :linked-calendars="true"
                :dateFormat="dd-mmm-yyyy"
                placeholder="Duration"
            />

            <div class="col-12 col-lg-3">
                <input type="search" v-model="searchMedicine[k].name" class="form-control mb-3 mb-lg-0" v-on:keyup="getMedicines(k)" autocomplete="off" placeholder="Search Medicine...">
                <div class="cls-sugg" v-if="medicines.length > 0 && selectKey == k">
                    <div v-for="(itm,i) in medicines" :key="i" class="prod-itm-select">
                      <a href="javascript:void(0)" id="element" class="btn-default show-element" @click="selectMedicine(itm,k)">
                        <input type="hidden" :v-model="input.medicine_name = itm.medicine_name" />
                        <input type="hidden" :v-model="input.medicine_id = itm.medicine_id" />
                        <span class="prdu-desc">{{itm.medicine_name}}</span>
                      </a>
                    </div>
                  </div>
                </div>
            <div class="col-12 col-lg-2"><input type="number" class="form-control mb-3 mb-lg-0" v-model="input.dosage" placeholder="Dosages"/></div>
            <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                <multiselect v-model="input.frequency_type" :options="frequency_types" :multiple="false"
                    :close-on-select="true" label="name"
                    :clear-on-select="false" :preserve-search="true" placeholder="Frequency Type"
                    :preselect-first="false" :showLabels="false" @input=updateIPDDetails()
                    ></multiselect>
            </div>
            <div class="col-12 col-lg-2"><input type="number" step=".25" class="form-control mb-3 mb-lg-0" v-model="input.frequency" placeholder="Frequency"/></div>
            <span class="col-12 col-lg-1">
                <svg xmlns="http://www.w3.org/2000/svg" @click="addField()" v-show="k == prescriptionMedicine.length-1" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" /></svg>
                <svg xmlns="http://www.w3.org/2000/svg"  @click="removeField(k)" v-show="k || ( !k && prescriptionMedicine.length > 1)" viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer"><path fill="none" d="M0 0h24v24H0z" /><path fill="#EC4899" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" /></svg>
            </span>
        </div>
    </div>

    <div class="col-md-4">
        <textarea rows="5" type="text" class="form-control" v-model="$v.typeform.notes.$model" name="notes" placeholder="Notes" :class="{ 'is-invalid':  $v.typeform.notes.$error }"/>
        <div v-if="$v.typeform.notes.$error" class="invalid-feedback">
            <span v-if="!$v.typeform.notes.required">Please enter notes</span>
        </div>
    </div>

    <div class="row mt-4 mb-3">
        <div class="col-md-4"></div>
        <div class="col-md-6">
            <b-button v-if="is_btn_spinner" variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
            <div v-else class="row">
                <button type="button"
                          class="col-md-4 btn btn-outline-secondary me-2" @click="hide()">Close</button>
                <button v-if="ipd_treatment" type="button" class="col-md-4 btn btn-gradient-primary me-2" @click="addUpdateTreatment()">Update Treatment</button>
                <button v-else type="button" class="col-md-4 btn btn-gradient-primary me-2" @click="addUpdateTreatment()">Add Treatment</button>
            </div>
          </div>
      </div>
  </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";

export default {
    components: {
      Multiselect,
      DateRangePicker,
    },
    data() {
        const now = new Date()
        const maxDate = new Date(new Date(now.getFullYear(), now.getMonth() + 3, now.getDate()))
        const minDate = new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate()))
        return {
          moment,
          ipd:null,
          ipd_treatment:null,
          prescriptionMedicine: [],
          medicines:[],
          searchMedicine:[],
          typeform: {
            treatment_name:'',
            notes:'',
            selected_ipd_treatment_status:{"id":"0", "name":"Ongoing"},
          },
          doctors:[],
          MAXDATE:maxDate,
          MINDATE:minDate,
          NOW:now,
          ward_list:[],
          room_list:[],
          room_bed_list:[],
          is_btn_spinner:false,
          myTimer:null,
          frequency_types : [{"id":"0", "name":"Mins"},{"id":"1", "name":"Hourly"},
                          {"id":"8", "name":"Daily"}, {"id":"7", "name":"Mid-Night(00 - 04)"},
                          {"id":"2", "name":"Early Morning(04 - 08)"}, {"id":"3", "name":"Morning(08 - 12)"},
                          {"id":"4", "name":"Noon(12 - 16)"}, {"id":"5", "name":"Evening(16 - 20)"},
                          {"id":"6", "name":"Night(20 - 00)"},],
          ipd_treatment_status : [{"id":"0", "name":"Ongoing"},{"id":"1", "name":"Completed"}]
        }
    },
    validations: {
        typeform: {
            treatment_name:{required},
            notes:{},
            selected_ipd_treatment_status:{},
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions("hospital",["getConcernDoctorListData", "getIPDRoomListData", "getIPDRoomBedListData", "admitPatientData", "getIPDWardListData", "getPrescriptionMedicineListData", "addIPDTreatmentData", "editIPDTreatmentData"]),
        show() {
            this.$refs.modal2.show();
        },

        hide() {
            this.$emit('hide', true)
            this.$refs.modal2.hide();
        },

        clearOldData() {
            this.prescriptionMedicine = []
            this.typeform.searchMedicine = []
            this.typeform.selected_ipd_treatment_status = {}
            this.typeform.medicine_name = []
            this.typeform.notes = ''
            this.typeform.treatment_name = ''
            this.searchMedicine = []
            this.medicines = []
        },

        receiveData(ipddetails, ipdTreatmentDetails) {
            this.ipd = ipddetails
            this.ipd_treatment = ipdTreatmentDetails
            this.setIPDTreatmentDetails()
        },

        setIPDTreatmentDetails() {
            if(this.ipd_treatment) {
                this.typeform.treatment_name = this.ipd_treatment.name
                this.typeform.notes = this.ipd_treatment.note
                this.typeform.selected_ipd_treatment_status = this.getIPDTreatmentStatus(this.ipd_treatment.treatment_status)

                if (this.ipd_treatment.treatment_medicine.length > 0) {
                    this.ipd_treatment.treatment_medicine.forEach((obj,i)=>{
                        this.searchMedicine.push({
                            id: obj.id,
                            name:obj.medicine_name
                        });
                        this.prescriptionMedicine.push({
                            id: obj.id,
                            medicine_id: obj.medicine_id,
                            medicine_name: [{key:obj.medicine_id, value:obj.medicine_name}],
                            dosage: obj.dosage,
                            frequency_type: this.getFrequencyType(obj.frequency_type),
                            frequency: obj.frequency,
                            duration: {startDate: obj.start_date, endDate:obj.end_date}
                        });
                    });
                } else {
                    this.addField()
                }
            } else {
                this.addField()
            }
        },

        addField() {
            this.prescriptionMedicine.push({
                id: "",
                medicine_id: "",
                medicine_name: [{key:'', value:''}],
                dosage: "",
                frequency_type: "",
                frequency: "",
                duration: ""
            });
            this.searchMedicine.push({
                id: "",
                name: ""
            });
        },

        removeField(index) {
            this.prescriptionMedicine.splice(index, 1);
            this.searchMedicine.splice(index, 1);
        },

        clearFieldData(index) {
            this.prescriptionMedicine[index].medicine_id = '';
            this.prescriptionMedicine[index].medicine_name = '';
        },

        getMedicines(index) {
            console.log("Hii Kaushal")

            clearTimeout(this.myTimer)
            this.myTimer = setTimeout(() => {
                this.selectKey = index;
                var mediFormData = new FormData();
                mediFormData.append('filter_value', this.searchMedicine[index].name);
                mediFormData.append('page', 1);
                mediFormData.append('total_record', '50');
                this.getPrescriptionMedicineListData(mediFormData).then((response) => {
                    if (response.response_code == 200) {
                        this.medicines = response.data;
                    }else{
                        this.clearFieldData(index);
                        this.medicines = [];
                    }
                });
         }, 500)
        },

        selectMedicine(val,i){
          this.searchMedicine[i].id = val.medicine_id;
          this.searchMedicine[i].name = val.medicine_name;
          this.medicines = [];
        },

        addUpdateTreatment() {
            let medicineNames = [];

            if (this.prescriptionMedicine.length == 0) {
                this.$toasted.error("Please select medicine", {duration: 2000,});
                return;
            }

            var isFormValid = true;
            this.prescriptionMedicine.forEach((el,i)=>{
                let medicineId = '0';
                let mname = '';
                mname = this.searchMedicine[i].name;

                if(!el.medicine_name[0].value){
                    console.log("If")
                    mname = this.searchMedicine[i].name;
                    medicineId = this.searchMedicine[i].id;
                } else{
                    console.log("If not")
                    mname = el.medicine_name[0].value;
                    medicineId = el.medicine_id;
                }

                console.log("---------------------------")
                console.log(el)
                console.log(this.searchMedicine[i])
                console.log(mname)
                console.log("---------------------------")

                if (!mname || mname == '') {
                    this.$toasted.error("Please enter medicine name", {duration: 2000,});
                    isFormValid = false;
                }
                if (!el.dosage || el.dosage == '') {
                    this.$toasted.error("Please enter medicine dosage", {duration: 2000,});
                    isFormValid = false;
                }
                if (!el.frequency_type || el.frequency_type == '' || !el.frequency_type.id || el.frequency_type.id == '') {
                    this.$toasted.error("Please select frequency type", {duration: 2000,});
                    isFormValid = false;
                }
                if (!el.frequency || el.frequency == '') {
                    this.$toasted.error("Please enter frequency", {duration: 2000,});
                    isFormValid = false;
                }
                if (!el.duration || !el.duration.startDate && !el.duration.endDate) {
                    this.$toasted.error("Please select duration", {duration: 2000,});
                    isFormValid = false;
                }

                let newMedicine = {
                    id: el.id,
                    medicine_id: medicineId,
                    medicine_name: mname,
                    dosage: el.dosage,
                    frequency_type: el.frequency_type.id,
                    frequency: el.frequency,
                    start_date: moment(String(el.duration.startDate)).format('YYYY-MM-DD HH:mm:ss'),
                    end_date: moment(String(el.duration.endDate)).format('YYYY-MM-DD HH:mm:ss')
                }
                medicineNames.push(newMedicine);
            });

            if(!isFormValid){
                return;
            }

            this.$v.$touch();

            if (!this.$v.$invalid) {
                this.is_btn_spinner = true;
                var bodyFormData = new FormData();
                bodyFormData.append('name', this.typeform.treatment_name);
                bodyFormData.append('ipd_id', this.ipd.id);
                bodyFormData.append('note', this.typeform.notes);
                bodyFormData.append('treatment_medicine', JSON.stringify(medicineNames));
                //bodyFormData.append('close_old_treatments', this.notes);

                if(this.ipd_treatment) {
                    bodyFormData.append('treatment_id', this.ipd_treatment.id);
                    bodyFormData.append('treatment_status', this.typeform.selected_ipd_treatment_status.id);
                    this.editIPDTreatmentData(bodyFormData).then((response) => {
                        this.is_btn_spinner = false;
                        if (response.response_code == 200) {
                            this.$toasted.success(response.message, {duration: 2000,});
                            this.hide()
                        } else{
                            this.$toasted.error(response.message, {duration: 2000,});
                        }
                    });
                } else {
                    this.addIPDTreatmentData(bodyFormData).then((response) => {
                        this.is_btn_spinner = false;
                        if (response.response_code == 200) {
                            this.$toasted.success(response.message, {duration: 2000,});
                            this.hide()
                        } else{
                            this.$toasted.error(response.message, {duration: 2000,});
                        }
                    });
                }
            }
        },

        getFrequencyType(frequency_type_code) {
            if(frequency_type_code == "0") {
                return {"id":"0", "name":"Mins"}
            } else if(frequency_type_code == "1") {
                return {"id":"1", "name":"Hourly"}
            } else if(frequency_type_code == "2") {
                return {"id":"2", "name":"Early Morning"}
            } else if(frequency_type_code == "3") {
                return {"id":"3", "name":"Morning"}
            } else if(frequency_type_code == "4") {
                return {"id":"4", "name":"Noon"}
            } else if(frequency_type_code == "5") {
                return {"id":"5", "name":"Evening"}
            } else if(frequency_type_code == "6") {
                return {"id":"6", "name":"Night"}
            } else if(frequency_type_code == "7") {
                return {"id":"7", "name":"Mid-Night"}
            } else if(frequency_type_code == "8") {
                return {"id":"8", "name":"Daily"}
            } else {
                return {"id":"0", "name":"Mins"}
            }
        },

        getIPDTreatmentStatus(treatment_status_code) {
          if(treatment_status_code == "0") {
            return {"id":"0", "name":"Ongoing"};
          } else if(treatment_status_code == "1") {
            return {"id":"1", "name":"Completed"};
          } else {
            return {"id":"0", "name":"Ongoing"};
          }
        },
    }
}
</script>
<style scoped>
.cls-sugg {width: auto;height: 172px;overflow: scroll;}
.prod-itm-select {
  padding: 9px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
